<template>
    <multi-select
        label="text"
        track-by="value"
        v-model="internalValue"
        :options="options"
        :multiple="multiple"
        placeholder="Дни недели"
    />
</template>

<script>

import MultiSelect from 'vue-multiselect';

export default {
    name: 'DaysOfWeekSelect',
    props: {
        value: {
            type: [Array, Object],
            required: false,
        },
        multiple: {
            type: Boolean,
            required: false,
            default: () => false,
        },
    },
    data() {
        return {
            options: [
                {
                    value: 1,
                    text: 'Понедельник'
                },
                {
                    value: 2,
                    text: 'Вторник'
                },
                {
                    value: 3,
                    text: 'Среда'
                },
                {
                    value: 4,
                    text: 'Четверг'
                },
                {
                    value: 5,
                    text: 'Пятница'
                },
                {
                    value: 6,
                    text: 'Суббота'
                },
                {
                    value: 0,
                    text: 'Воскресенье'
                },
            ]
        }
    },
    computed: {
        internalValue: {
            get: function () {
                const value = Array.isArray(this.value) ? this.value : [this.value];

                return this.options.filter((option) => value.includes(option.value));
            },
            set: function (value) {
                this.$emit('input', Array.isArray(value) ? value.map((option) => option.value) : value.value);
            },
        },
    },
    components: {
        MultiSelect,
    },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
