<template>
    <b-container>
        <b-card>
            <errors-bag-list/>
            <b-form>
                <b-form-group label="Наименование">
                    <b-form-input v-model="form.name"/>
                </b-form-group>

                <b-form-group label="Подразделение">
                    <department-select v-model="form.department"/>
                </b-form-group>
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="По дням недели">
                            <days-of-week-select v-model="form.planning.days_of_week" multiple/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <b-form-group label="Дата начала">
                            <b-form-datepicker
                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                :start-weekday="1"
                                :min="moment().format('YYYY-MM-DD')"
                                :max="form.ended_at"
                                locale="ru"
                                placeholder=""
                                v-model="form.started_at"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label="Дата завершения">
                            <b-form-datepicker
                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                :start-weekday="1"
                                :min="form.started_at"
                                locale="ru"
                                placeholder=""
                                v-model="form.ended_at"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <checklist-template-select v-model="form.checklistTemplateItems"/>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" class="text-right">
                        <b-button v-if="form.id" variant="primary" @click="update()">Сохранить</b-button>
                        <b-button v-else variant="primary" @click="create()">Создать</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>
    </b-container>
</template>

<script>
import {PLANNING_SANITARY_INSPECTIONS_ENDPOINT} from '@utils/endpoints';

import ErrorsBagList           from '@components/_common/ErrorsBagList';
import moment                  from 'moment';
import DaysOfWeekSelect        from './components/DaysOfWeekSelect';
import ChecklistTemplateSelect from './components/ChecklistTemplateSelect';
import {DepartmentSelect}      from '@components';

export default {
    name: 'Form',
    props: {
        id: {
            type: Number,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            form: {
                id: this.id,
                name: null,
                planning: {
                    days_of_week: [],
                },
                started_at: null,
                ended_at: null,
                department: {},
                checklistTemplateItems: [],
            },
            moment,
        }
    },
    components: {
        ErrorsBagList,
        DaysOfWeekSelect,
        ChecklistTemplateSelect,
        DepartmentSelect,
    },
    methods: {
        load() {
            const params = {
                with: ['checklistTemplateItems'],
            };

            this.$http.get(
                `${PLANNING_SANITARY_INSPECTIONS_ENDPOINT}/${this.form.id}`,
                {params}
            ).then((response) => this.form = response.data.data);
        },
        create() {
            this.$http.post(
                PLANNING_SANITARY_INSPECTIONS_ENDPOINT,
                {
                    name: this.form.name,
                    planning: this.form.planning,
                    started_at: this.form.started_at,
                    ended_at: this.form.ended_at,
                    department_id: this.form.department ? this.form.department.id : null,
                    checklist_template_item_ids: this.form.checklistTemplateItems.length
                        ? this.form.checklistTemplateItems.map((item) => item.id)
                        : null,
                }
            ).then(() => {
                this.$router.push({name: 'Planning'});
            }).catch((error) => {
                const errors = error.data.errors
                    ? Object.values(error.data.errors).flat()
                    : [error.data.message];

                ErrorsBag.discard();
                ErrorsBag.fill(errors);
                window.scrollTo({top: 0, behavior: 'smooth'});
            });
        },
        update() {
            this.$http.put(
                `${PLANNING_SANITARY_INSPECTIONS_ENDPOINT}/${this.form.id}`,
                {
                    name: this.form.name,
                    planning: this.form.planning,
                    started_at: this.form.started_at,
                    ended_at: this.form.ended_at,
                    department_id: this.form.department ? this.form.department.id : null,
                    checklist_template_item_ids: this.form.checklistTemplateItems.length
                        ? this.form.checklistTemplateItems.map((item) => item.id)
                        : null,
                }
            ).then(() => {
                this.load();
                this.$bvToast.toast('Успешно обновлено', {variant: 'success'});
            }).catch((error) => {
                const errors = error.data.errors
                    ? Object.values(error.data.errors).flat()
                    : [error.data.message];

                ErrorsBag.discard();
                ErrorsBag.fill(errors);
                window.scrollTo({top: 0, behavior: 'smooth'});
            });
        },
    },
    mounted() {
        if (this.id) {
            this.load();
        }
    }
}
</script>
