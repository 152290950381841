<template>
    <div>
        <b-form-group label="Шаблоны чек-листов">
            <multi-select
                label="text"
                @input="input"
                track-by="value"
                v-model="selected"
                :options="options"
                :multiple="true"
                placeholder="Чек-листы"
            />
        </b-form-group>
        <b-form-group>
            <b-card no-body v-show="selected.length" v-for="(option, checklistIndex) in selected" class="mb-1">
                <b-card-header v-b-toggle="'item-' + option.value">
                    {{ option.text }}
                </b-card-header>
                <b-collapse :id="'item-' + option.value" class="mt-2">
                    <b-card-body>
                        <b-row
                            v-show="option.template_items.length"
                            v-for="(item, index) of option.template_items"
                            :key="index"
                            class="d-flex justify-content-center"
                        >
                            <b-col cols="6">
                                <b-form-group>
                                    {{ item.name }}
                                </b-form-group>
                            </b-col>
                            <b-col cols="3">
                                <b-form-group>
                                    <b-button
                                        variant="danger"
                                        @click="destroyItem(checklistIndex, index)"
                                    >
                                        <b-icon-x/>
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </b-form-group>
    </div>
</template>

<script>

import MultiSelect from 'vue-multiselect';
import {CHECKLIST_TEMPLATES_ENDPOINT} from '@utils/endpoints';

export default {
    name: 'ChecklistTemplateSelect',
    props: {
        value: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
    data() {
        return {
            options: [],
            selected: [],
        }
    },
    components: {
        MultiSelect,
    },
    methods: {
        load() {
            const params = {
                with: ['items'],
                limit: 1000,
            }

            this.$http.get(
                CHECKLIST_TEMPLATES_ENDPOINT,
                { params }
            ).then((response) => {
                this.options = response.data.data.data.map((data) => {
                    return {
                        template_items: data.items,
                        value: data.id,
                        text: data.name,
                    }
                });
            });
        },
        input() {
            this.$emit('input', this.selected.map((checklistTemplate) => checklistTemplate.template_items).flat());
        },
        destroyItem(checklistIndex, index) {
            this.selected[checklistIndex].template_items.splice(index, 1);

            if (!this.selected[checklistIndex].template_items.length) {
                this.selected.splice(checklistIndex, 1);
            }

            this.input();
        }
    },
    watch: {
        options: function() {
            // Собираем карту id чек-листа - критерии
            let checklistTemplates = {};
            this.value.forEach((option) => {
                if (!checklistTemplates[option.checklist_template_id]) {
                    checklistTemplates[option.checklist_template_id] = [];
                }
                checklistTemplates[option.checklist_template_id].push(option);
            });

            // Устанавливаем выбранные чек-листы и критерии
            this.options.forEach((option) => {
                if (checklistTemplates.hasOwnProperty(option.value)) {
                    this.selected.push({
                        template_items: checklistTemplates[option.value],
                        value: option.value,
                        text: option.text,
                    });
                }
            });
        },
    },
    mounted() {
        this.load();
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
