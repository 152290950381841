<template>
    <multi-select
        v-model="modelValue"
        label="name"
        track-by="id"
        :multiple="multiple"
        :disabled="disabled"
        :options="options"
        show-labels
        close-on-select
        placeholder="Подразделения"
    />
</template>

<script>
import MultiSelect from 'vue-multiselect';
import {DEPARTMENT_ENDPOINT} from '@utils/endpoints';

export default {
    name: 'DepartmentSelect',
    props: {
        value: {
            type: [Object, Array],
        },
        multiple: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
        allowedRoles: {
            required: false,
            default: () => true,
        },
        salaryEnabled: {
            type: Boolean,
            required: false,
            default: () => null,
        },
    },
    computed: {
        modelValue: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            },
        },
    },
    data() {
        return {
            options: [],
        };
    },
    components: {MultiSelect},
    methods: {
        load() {
            const params = {
                limit: 99,
                filters: {},
                sort: 'name',
                sortDirection: 'asc'
            };

            params.filters.allowed_roles = this.allowedRoles || [];

            if (this.salaryEnabled) {
                params.filters.salary_enabled = this.salaryEnabled;
            }

            this.$http.get(
                DEPARTMENT_ENDPOINT,
                {
                    params: params,
                }
            ).then((response) => {
                this.options = response.data.data.data;
            }).catch(() => {
                this.$bvToast.toast('Ошибка загрузки подразделений', {variant: 'danger'});
            });
        },
    },
    mounted() {
        this.load();
    }
}
</script>
