<template>
    <multi-select
        v-if="workPositions"
        v-model="model"
        :options="workPositions"
        :disabled="disabled"
        searchable
        label="name"
        select-label=""
        selected-label=""
        deselect-label=""
        show-labels
        close-on-select
        placeholder="Выберите должность"
    />
</template>

<script>
import MultiSelect from 'vue-multiselect';

import {WORK_POSITIONS_ENDPOINT} from '@utils/endpoints';

export default {
    name: 'WorkPositionSelect',
    components: {MultiSelect},
    props: {
        value: {
            type: Object,
            default: () => null
        },
        disabled: {type: Boolean, default: false}
    },
    computed: {
        model: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            }
        },
    },
    data() {
        return {
            workPositions: [],
            currentValue: null
        };
    },
    methods: {
        load() {
            this.workPositions = [];
            let params = {orders: {order: 'asc', name: 'asc'}, limit: 100};

            this.$http
                .get(WORK_POSITIONS_ENDPOINT, {params})
                .then(response => {
                    this.workPositions = response.data.data.data;
                    this.workPositions.unshift({name: 'Выберите должность'});
                });
        }
    },
    created() {
        this.load();
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
