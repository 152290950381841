<template>
    <select class="form-control" v-model="internalValue">
        <option :value="null">Выберите площадку</option>
        <option v-for="option in options" :key="option.id" :value="option.id">{{ option.name }}</option>
    </select>
</template>

<script>
import {AreaService} from '@services';

export default {
    name: 'AreaSelect',
    props: {
        value: Number,
        required: false,
    },
    computed: {
        internalValue: {
            get: function () {
                return this.value;
            },
            set : function (value) {
                this.$emit('input', value);
            },
        },
    },
    data() {
        return {
            options: [],
        };
    },
    methods: {
        load() {
            AreaService.getWithPaginate().then(({data}) => {
                this.options = data;
            });
        },
    },
    mounted() {
        this.load();
    }
}
</script>
